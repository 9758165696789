<template>
  <div class="main-wrapper">
    <div class="row" style="margin-top:90px"> 
        <div class="col-md-12">
            <img src="/static/images/zcp-home.png" alt="" style="width:100%">

        </div>
        
    </div>
  </div>
</template>
<script>
export default {
};
</script>
